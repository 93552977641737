@import 'assets/style/variables';
@import 'assets/style/circle';

$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "roboto-fontface/css/roboto/sass/roboto-fontface";
$material-icons-font-path: '~material-icons/iconfont/';
@import 'material-icons/iconfont/material-icons.scss';

html, body {
  color: $pozi_black;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  margin: 0;

  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
  }
}

div {
  box-sizing: border-box;
}

button, .button {
  background-color: $button-background-color;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  outline: 0;
  padding: 0 10px;
  height: 32px;
  cursor: pointer;

  &:disabled {
    opacity: .5;
  }

  &.with-icon {
    align-items: center;
    display: flex;

    span.btn-icon {
      height: auto;
      width: 12px;
      margin-left: 6px;

      svg {
        height: auto;
        width: 100%;

        path:not([fill="none"]) {
          fill: #fff;
        }
      }

      img {
        height: auto;
        width: 100%;
        filter: $white_svg;
      }


    }

    &.light {
      span.btn-icon img {
        filter: $pozi-dark_svg;
      }
    }

    span.material-icons, i.material-icons {
      font-size: 18px;
      line-height: 12px;

      &.red {
        color: $pozi_red;
      }
    }

    span + span, i + span {
      margin-left: 10px;
    }
  }

  &.grey {
    background-color: $pozi_grey;
  }

  &.green {
    background-color: $wine_green;
  }

  &.pozi-green {
    background-color: $pozi_green;
  }

  &.red {
    background-color: $pozi_red;
  }

  &.white {
    background-color: $pozi_white;
    color: $pozi_dark;
  }

  &.real-white {
    background-color: white;
    color: $pozi_black;
  }

  &.light {
    background-color: $pozi_light;
    color: $pozi_dark;
  }

  &.yellow {
    background-color: #F0C800;
    color: #FEFEFE;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  background-color: $pozi_light;
  border-radius: 4px;
  height: 32px;
  padding: 8px;
  color: $pozi_black;

  .text {
    margin-left: 8px;
  }

  .material-icons, .material-icons-outlined {
    color: $pozi_dark;
    font-size: 18px;
  }

  &.disabled, &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.checkbox {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $pozi_light;
  cursor: pointer;

  .material-icons {
    display: none;
    font-size: 12px;
  }

  &.checked {
    background: $pozi_dark;
    color: white;
    border: none;

    .material-icons.check {
      display: inline-block;
    }
  }

  &.partial {
    background: $pozi_grey;
    color: white;
    border: none;

    .material-icons.line {
      display: inline-block;
    }
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.green {
    background-color: $wine_green;
  }

  &.dark {
    background-color: $pozi_dark;
  }

  &.red {
    background-color: $pozi_red;
  }

  &.yellow {
    background-color: $scs_yellow;
  }

  &.blue {
    background-color: $hydrolog_blue;
  }

  &.grey {
    background-color: $pozi_grey;
    opacity: 0.4;
  }
}

.input {
  display: inline-flex;
  align-items: center;
  background-color: $pozi_light;
  border-radius: 4px;
  height: 32px;
  padding: 8px;
  color: $pozi_black;

  input {
    background-color: transparent;
    color: $pozi_black;
    padding-left: 4px;
    width: 124px;
  }

  .material-icons {
    font-size: 18px;
  }
}

.dropdown-container {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  z-index: 1111;

  .text {
    padding-top: 2px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .input {
    z-index: 1;
  }

  .up {
    display: none;
  }

  &.date {
    margin-right: 2px;

    .dropdown-body {
      border-top-right-radius: 0;
    }

    .input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .text {
        min-width: 70px;
      }
    }
  }

  .dropdown-body {
    list-style: none;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    transition: box-shadow 0.3s;
    overflow: hidden;

    li {
      padding: 0 8px;
      height: 0;
      display: flex;
      transition: height 0.3s, padding-top 0.3s, padding-bottom 0.3s;
      align-items: center;

      .dot {
        margin-right: 0;
        margin-left: auto;
      }

      &:first-of-type {
        padding-left: 32px;
      }

      span {
        display: inline-block;
        font-size: 12px;
      }

      .checkbox {
        width: 14px;
        height: 14px;
        border-radius: 2px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
      }

      /*.material-icons {
        display: none;
        font-size: 12px;
      }*/

      &.checked {
        .material-icons {
          display: inline-block;
        }
      }
    }

    &.multi-select {
      li {
        .checkbox {
          background-color: $pozi_light;
        }

        &.checked {
          .checkbox {
            background-color: $pozi_dark;
            color: white;
          }
        }

        &.select-all {
          padding-left: 32px;

          .title {
            border-top: 1px solid $pozi_grey;
            display: block;
            width: 100%;
            padding-top: 10px;
          }
        }
      }
    }
  }

  &.open {
    .input {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .down {
      display: none;
    }

    .up {
      display: block;
    }

    .dropdown-body {
      box-shadow: 0 4px 20px 0 rgba(40, 40, 40, 0.2);

      li {
        height: auto;

        &:first-of-type {
          padding-top: 42px;
        }

        padding-bottom: 10px;
      }
    }
  }
}


.input-row {
  background: $input-background;
  border-radius: 4px;
  margin-bottom: 18px;
  padding-left: $sides-padding;

  > label {
    margin-right: 6px;
  }

  > * {
    height: 32px;
    line-height: 32px;
  }

  &.has-error {
    border: 1px solid red;

    > * {
      color: red;
    }
  }
}

label, select, input, textarea {
  background: $input-background;
  color: $input-label-color;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  display: inline-block;
  font-size: 12px;
}

a {
  color: $text-color-secondary;
  text-decoration: none;
}

[title="JavaScript charts"] {
  display: none !important;
}

.subheader {
  align-items: center;
  background-color: $subheader-background-color;
  display: flex;
  height: $subheader-height;
  padding-left: 20px;
  padding-right: 20px;

  h1 {
    font-family: 'Roboto', serif;
    font-size: 20px;
    font-weight: 500;
    margin-left: 0;
    margin-right: auto;
  }

  .title {
    font-family: Roboto-Medium, sans-serif;
    font-size: 20px;
    margin-right: auto;
    margin-left: 0;
  }

  > * {
    margin-left: 10px;
  }

  &.second-row {
    padding: 0 20px;
    user-select: none;
    margin-top: -10px;
    margin-left: 0;
    height: $tab-height;

    span {
      margin-left: 0;
      margin-right: 10px;

      &:not(:last-of-type) {
        &:after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          border-radius: 50%;
          margin-left: 10px;
          width: 5px;
          height: 5px;
          background-color: $pozi_dark;
        }
      }


    }
  }
}

.tabs {
  background-color: $subheader-background-color;
  padding: 0 20px;
  user-select: none;
  margin-top: -10px;

  .tab {
    border-bottom: 2px solid $subheader-background-color;
    color: $pozi_black;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: calc(#{$tab-height});
    line-height: calc(#{$tab-height});
    margin-right: 20px;
    text-transform: uppercase;

    &.active {
      border-color: $text-color-secondary;
      font-weight: bold;

    }

    &.disabled {
      opacity: .3;
      pointer-events: none;
    }
  }
}

.bold {
  font-weight: bold;
}

.medium {
  font-family: Roboto-Medium, sans-serif;
}

.icon > svg {
  vertical-align: middle;
}

div.three-state-checkbox {
  cursor: pointer;

  > .all {
    color: $pozi_dark;
  }

  > .partial {
    color: $pozi_grey;
  }

  > .none {
    color: $pozi_white;
  }
}

input[type="checkbox"].checkbox {
  visibility: hidden;
  height: 24px;
  margin: 0;
  width: 24px;

  &:after {
    visibility: visible;
    //noinspection CssNoGenericFontName
    font-family: 'Material Icons';
    color: $pozi_light;
    content: 'check_box_outline_blank';
    display: block;
    font-size: 24px;
  }

  &:checked:after {
    content: 'check_box';
    color: $pozi_red;
  }

}

.chart {
  svg {
    g[aria-labelledby] {
      display: none;
    }
  }
}

input[type="radio"].radio {
  display: none;

  & + label {
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: block;
    height: 14px;
    width: 100%;

    > span {
      box-sizing: border-box;
      display: inline-block;
    }

    span.radio-button {
      background-color: #fff;
      border-radius: 50%;
      border: 2px solid #fff;
      box-shadow: $pozi_grey 0 0 0 1px;
      display: inline-block;
      height: 14px;
      margin-right: 4px;
      vertical-align: bottom;
      width: 14px;
    }
  }

  &:checked + label > span.radio-button {
    background-color: $text-color-secondary;
  }
}

.number-picker {
  width: 100%;

  > * {
    display: inline-block;
  }

  input[type="number"] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      display: none;
      margin: 0;
    }

    -moz-appearance: textfield;

    background-color: #fff;
    height: 32px;
    vertical-align: middle;
    width: calc(100% - 64px);
  }

  span.minus, span.plus {
    background-color: $pozi_grey;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    width: 32px;
  }

  span.minus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  span.plus {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

}

//noinspection CssInvalidPropertyValue
.icon--inherit svg path:not([fill="none"]), .icon--inherit svg rect:not([fill="none"]) {
  fill: inherit !important;
  stroke: inherit !important;
}

.clearfix {
  display: block;

  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

div.input-group {
  align-items: center;
  background: $input-background;
  border-radius: 4px;
  color: $input-label-color;
  display: flex;
  justify-content: space-between;
  padding: 9px;

  input {
    height: auto;
    padding: 0;
    width: calc(100% - 20px);
  }

  span.material-icons {
    color: $input-svg-color;
    font-size: 14px;

    &.postfix {
      margin-left: 10px;
    }
  }

  &.disabled > input, &.disabled > span {
    color: $pozi_grey;
  }
}

.datepicker-block {
  display: flex;

  > * {
    border-radius: 0;
    margin-right: 2px;
  }

  .input {
    border-radius: 0;
  }

  &.disabled, .disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  .start-date, .end-date {
    input[type="date"] {
      position: relative;
      padding-left: 5px;
      width: 115px;
      color: $pozi_black;
      overflow: hidden;

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        top: -150%;
        left: -150%;
        width: 300%;
        height: 300%;
        color: transparent;
        background: transparent;

        outline: none !important;
      }

      &::-webkit-inner-spin-button {
        z-index: 1;
      }

      &::-webkit-clear-button {
        z-index: 1;
      }
    }

    .text-only {
      pointer-events: none;
    }
  }

  .start-date {
    margin-right: 2px;

    .material-icons {
      transform: rotate(90deg);
    }

  }

  :last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 0;
  }

  > :first-child {
    border-radius: 4px 0 0 4px;
  }
}

.input-block {
  align-items: center;
  display: flex;
  font-size: 12px;

  app-dropdown {
    padding: 0;
  }

  &.white {
    input, label, > * {
      background-color: #fff;
    }

    .switch > input[type="checkbox"] + label:before {
      background-color: $pozi_light;
    }
  }

  input {
    border: 0;
    outline: 0;

    &[type="date"] {
      padding: 8px 10px;
    }
  }

  > .input-group {
    border-radius: 0;
    padding: 0 10px;

    > input[type="date"] {
      padding: 8px 10px;
    }
  }

  > * {
    background-color: $pozi_light;
    margin-right: 2px;
    padding: 10px;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-right: 0;
    }
  }
}

div.switch {
  align-items: center;
  display: flex;
  font-size: 12px;
  padding: 10px;
  user-select: none;

  .material-icons {
    font-size: 18px;
    line-height: 14px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  &.disabled {
    cursor: not-allowed;

    > * {
      cursor: not-allowed;
    }

    label, .material-icons {
      opacity: .5;
    }
  }

  label {
    color: $pozi_black !important;
    height: auto;
    margin-right: 0;
    white-space: nowrap;
    width: auto;
  }

  input[type="checkbox"] {
    display: none;
    $radius: 7px;
    $handle-width: 20px;

    & + label {
      background-color: $pozi_grey;
      border-radius: 5px;
      height: 10px;
      margin-left: 10px;
      position: relative;
      width: $handle-width;
      transition: background-color .2s;

      &:before {
        background-color: #fff;
        content: ' ';
        display: block;
        position: absolute;
        left: 0 - $radius;
        top: 5 - $radius;
        height: 2 * $radius;
        width: 2 * $radius;
        border-radius: 50%;
        transition: background-color .2s, left .2s;
      }
    }

    &:checked + label {
      background-color: #{rgba($pozi_red, 0.2)};

      &:before {
        background-color: $pozi_red;

        left: $handle-width - $radius;
      }
    }
  }
}

div.switch2 {
  align-items: center;
  background-color: $pozi_light;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 10px;
  user-select: none;

  label {
    color: $pozi_black !important;
    font-size: 18px;
    height: 18px;
    margin-right: 0;
    white-space: nowrap;
    width: auto;
  }

  input[type="checkbox"] {
    display: none;
    $radius: 5px;
    $handle-width: 22px;

    & + label {
      background-color: $pozi_grey;
      border-radius: 7px;
      height: 14px;
      margin-left: 8px;
      position: relative;
      width: $handle-width;
      transition: background-color .2s;
      cursor: pointer;

      &:before {
        background-color: #ffffff;
        content: ' ';
        display: block;
        position: absolute;
        left: 7 - $radius;
        top: 7 - $radius;
        height: 2 * $radius;
        width: 2 * $radius;
        border-radius: 50%;
        transition: background-color .2s, left .2s;
      }
    }

    &:checked + label {
      background-color: $pozi_red;

      &:before {
        left: $handle-width - (2 * $radius + 2);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.refresh-data {
  font-size: 18px;
  padding: 6px;
}

span.green, i.green {
  color: $wine_green;
}

span.black, i.black {
  color: $pozi_black;
}

span.grey, i.grey {
  color: $pozi_grey;
}

span.red, i.red {
  color: $pozi_red;
}

span.yellow, i.yellow {
  color: $scs_yellow;
}

.form-group {
  align-items: center;
  display: flex;
  position: relative;

  > label {
    background-color: initial;
    flex-basis: 200px;
    flex-shrink: 0;
  }

  &.required {
    > label {
      &::after {
        content: '*';
        color: $pozi-red;
        display: inline-flex;
        margin-left: 2px;
      }
    }
  }

  > input, select {
    background-color: $pozi_light;
    border: 1px solid $pozi_light;
    border-radius: 8px;
    margin-left: auto;
    padding: 8px;
    width: 100%;
    // width: 360px;

    select {
      padding-right: 16px;
    }

    &:disabled {
      background-color: #fff;
      color: $pozi_grey;
    }
  }

  .error-label {
    display: none;
  }

  &.has-error {
    input {
      border: 1px solid $pozi-red;
    }

    .error-label {
      color: $pozi-red;
      display: block;
      position: absolute;
      right: 10px;
    }
  }

  &.with-suffix {
    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .input-suffix {
      display: flex;
      align-items: center;
      height: 32px;
      width: 32px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      background-color: $pozi_light;
      color: $pozi_grey;
      flex-shrink: 0;

      &.material-icons {
        font-size: 16px;
      }
    }
  }
}


.tippy-box[data-theme~='pozi'] {
  background: #fff;
  border: 1px solid $pozi_light;
  color: black;
  filter: drop-shadow(0px 4px 12px rgba(44, 52, 60, 0.24));
  font-family: Arial, serif;
  font-size: 12px;

  .tippy-content {
    padding: 0;
  }

  &[data-placement^='top'] > .tippy-arrow {
    &:before {
      border-top-color: #fff
    }

    &:after {
      border-top-color: $pozi_light;
      border-width: 7px 7px 0;
      top: 17px;
      left: 1px
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    &:before {
      border-bottom-color: #fff
    }

    &:after {
      border-bottom-color: $pozi_light;
      border-width: 0 7px 7px;
      bottom: 17px;
      left: 1px
    }
  }

  &[data-placement^='left'] > .tippy-arrow {
    &:before {
      border-left-color: #fff
    }

    &:after {
      border-left-color: $pozi_light;
      border-width: 7px 0 7px 7px;
      top: 1px;
      left: 17px
    }
  }

  &[data-placement^='right'] > .tippy-arrow {
    &:before {
      border-right-color: #fff
    }

    &:after {
      border-right-color: $pozi_light;
      border-width: 7px 7px 7px 0;
      top: 1px;
      right: 17px
    }
  }
}

app-dropdown.ng-invalid .dropdown-container {
  border: 1px solid $pozi_red !important;
}

.tippy-box[data-theme~='dropdown'] {
  background-color: #fefefe;
  border: 0;
  border-radius: 8px;
  color: #14181c;
  box-shadow: 0px 4px 16px 0px #2c343c29;
  font-size: 12px;

  > .tippy-arrow {
    display: none;
  }

  $check-icon-size: 16px;
  $check-icon-margin-right: 8px;

  .dropdown-content {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;

    .dropdown-item-group {
      color: #b4c8dc;
      padding: 4px calc($check-icon-size + $check-icon-margin-right);
    }

    .dropdown-select-all {
      border: 0;
      border-radius: 0;
      gap: 0;
      padding: 4px 20px 4px 0;
      border-bottom-color: #b4c8dc;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .item-list {
      cursor: pointer;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        align-items: center;
        display: flex;
        padding: 4px 20px 4px 0;
        white-space: nowrap;
      }
    }

    .check-placeholder {
      width: calc($check-icon-size + $check-icon-margin-right);
      height: 1px;
      display: block;
      flex-shrink: 0;
    }

    i {
      margin-right: $check-icon-margin-right;
      font-size: $check-icon-size;
    }
  }
}